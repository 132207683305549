import * as React from "react";
import { Link } from "gatsby"

const OnboardingButton = (props) => {

    const {link, img, title, subtitle, description} = props;

    return (
        <Link 
            className="onboarding-button"
            to={link}
        >
            <div>
                <div className="button-img">
                    <img src={img} height="40" alt="icon" />
                </div>
            </div>
            <div className="d-flex flex-column">
                <h2 className="fs-20 text-black text-bold mb-2">{title}</h2>
                <p className="text-dark-grey mb-1">{subtitle}</p>
                {description && <p className="fs-14 text-grey">{description}</p>}
            </div>
        </Link>
    )
}

export default OnboardingButton;
