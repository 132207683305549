import React from "react";
import '../assets/app.scss';
import AppLayout from "../components/AppLayout";
import OnboardingButton from '../components/Buttons/OnboardingButton';
import {onboardingData} from '../data/onboarding.js';
import * as queryString from "query-string";
import { seoOnboarding } from "../data/seo";
import Seo from "../components/seo";

import {Helmet} from 'react-helmet';

const OnBoardingApp = ({location}) => {

  const isBrowser = typeof window !== "undefined"
  const {title, subtitle, buttons} = onboardingData;
  const { utm_source } = queryString.parse(location.search);
  isBrowser && sessionStorage.setItem('utm', utm_source);


  return (
    <AppLayout>
        <div className="container-custom">

          <Seo
            title={seoOnboarding.title}
            description={seoOnboarding.description}
          />

          

          <Helmet
            bodyAttributes={{
              class: 'bk-extra-light-grey'
            }}
          />

          <div className="row justify-content-center">
            <div className="col-12 col-lg-7">
              <h1 className="heading-2 text-black">{title}</h1>
              <p className="heading-3 text-dark-grey text-regular mb-5">{subtitle}</p>
              {
                buttons.map((button, i) => (
                  <OnboardingButton
                    key={i}
                    link={button.link}
                    img={button.img}
                    title={button.title}
                    subtitle={button.subtitle}
                    description={button.description}
                    value={button.value}
                  />
                ))
              }
            </div>  
          </div>
        </div>
    </AppLayout>
  )
}

export default OnBoardingApp;
